<template>
  <div id="download" class="section">
    <div class="download container-fluid position-relative p-0 m-0">
      <div class="container-fluid p-0">
        <div data-aos="fade-in"
          class="col-12 statement d-flex flex-column justify-content-center align-items-center pt-4 mt-5">
          <div class="col-12 text-center mb-3 pt-4">
            <div class="title">Download the report</div>
          </div>
          <div class="col-12 text-center">
            <h3>
              Click the button below to access the full PDF report. <br />
              View in your browser or, download.
            </h3>
          </div>
        </div>
        <div class="col-12 btn-container position-relative">
          <div :class="[hoveringDownload ? 'download-text-light' : '']" @click="download" class="download-text">
            Read the report
          </div>
          <a target="_blank" href="https://7my37nsm8cb.typeform.com/to/R8rAEkIv">
            <svg class="ripple" xmlns="http://www.w3.org/2000/svg" height="100%" width="100%">
              <g id="anims">
                <circle id="rp1" r="1.8em" />
                <circle id="rp2" r="1.8em" />
                <circle id="rp3" r="1.8em" />
                <circle id="rp4" r="1.8em" />
              </g>

              <circle @mouseover="hoveringDownload = true" @mouseout="hoveringDownload = false" @click="download"
                class="download-btn" r="5em" cx="50%" cy="50%" stroke="#e8fcd4" stroke-width="1" />
            </svg>
          </a>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center">
          <div class="col-12 col-md-7 d-flex justify-content-evenly align-items-center mb-5">
            <img class="penn-logo img-fluid" src="@/assets/img/xp-logo.png" alt="" />

            <a href="https://vibe-lab.org/">
              <img class="logo img-fluid" src="@/assets/img/vibelab-logo.png" alt="" /></a>

          </div>
        </div>
        <div class="col-12 text-center share-text">Share</div>
        <div class="col-12 d-flex mb-5 justify-content-center align-items-center">
          <div class="col-md-4 col-12 py-2 share-container d-flex justify-content-evenly align-items-center">
            <FacebookButton class="share-button--circle" btnText url="https://xpoutcomesreport.vibe-lab.org"
              description="VibeLab: XP Outcomes Report" />
            <TwitterButton class="share-button--circle" btnText url="https://xpoutcomesreport.vibe-lab.org"
              description="VibeLab: XP Outcomes Report" />
            <LinkedInButton class="share-button--circle" btnText url="https://xpoutcomesreport.vibe-lab.org"
              description="VibeLab: XP Outcomes Report" />
            <WhatsAppButton class="share-button--circle" btnText url="https://xpoutcomesreport.vibe-lab.org"
              description="VibeLab: XP Outcomes Report" />
            <EmailButton class="share-button--circle" btnText url="https://xpoutcomesreport.vibe-lab.org"
              description="VibeLab: XP Outcomes Report" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TwitterButton from "vue-share-buttons/src/components/TwitterButton";
import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
import LinkedInButton from "vue-share-buttons/src/components/LinkedInButton";
import WhatsAppButton from "vue-share-buttons/src/components/WhatsAppButton";
import EmailButton from "vue-share-buttons/src/components/EmailButton";

export default {
  components: {
    TwitterButton,
    FacebookButton,
    LinkedInButton,
    WhatsAppButton,
    EmailButton,
  },
  data() {
    return {
      hoveringDownload: false,
    };
  },
};
</script>


<style scoped>
.share-container {
  border:1px solid #e8fcd4;
  border-radius:40px;
}
.logo {
  width: 10rem;
}

.penn-logo {
  width: 7rem;
}
.share-text {
    color: #e8fcd4;
}
h3 {
  font-family: SkModernistRegular;
}
.download-text {
  height: 1.3rem;
  width: 8rem;
  bottom: 0;
  text-align: center;
  top: 0;
  margin: auto;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  color: #e8fcd4;
}
.download-text-light {
  color: #201f1d;
}
.download-text {
  pointer-events: none;
}

.download-btn {
  transition: transform ease 100ms, fill 0.15s ease-in-out;
  transform-origin: center center;
}
.download-btn:hover {
  transform-origin: center center;
  transform: scale(0.9);
  cursor: pointer;

  fill: #e8fcd4 !important;
}

/* circle:last-child:hover {
  fill: #d9d9d9 !important;
} */

.title {
  font-family: SkModernistRegular;
  line-height: 2rem;
  font-size: 1.8rem;
  color: #e8fcd4;
}
@media (min-width: 768px) {
  .title {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}

.btn-container {
  height: 34rem;
}

.ripple {
  bottom: 0;
  top: 0;
  margin: auto;
  position: absolute;

  left: 0;
  right: 0;
}
.download {
  height: auto;
  min-height: 100vh;
  background: #201f1d;
  overflow: hidden;
}

circle {
  fill: #747474;
  opacity: 0.3;
}
svg > circle:last-child {
  fill: #201f1d !important;
  opacity: 1;
}
svg g {
  transform: translate(50%, 50%);
}
#rp1 {
  animation: ripple1 2s linear infinite;
  position: absolute;
  bottom: 0;
}
@keyframes ripple1 {
  0% {
    transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(8.5);
    opacity: 0;
  }
}
h3 {
  color: #e8fcd4;
}

#rp2 {
  animation: ripple2 2s linear infinite;
}
@keyframes ripple2 {
  0% {
    transform: scale(3.5);
  }
  100% {
    transform: scale(5.5);
  }
}
#rp3 {
  animation: ripple3 2s linear infinite;
}
@keyframes ripple3 {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(3.5);
  }
}
#rp4 {
  animation: ripple4 2s linear infinite;
}
@keyframes ripple4 {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1.5);
  }
}


</style>