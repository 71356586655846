<template>
  <div id="themes" class="section themes">
    <div class="container-fluid row d-flex justify-content-center m-0">
      <div class="col-12 title text-center mb-5 pb-5 pt-5  mt-5">
        XP Key Themes
      </div>

      <div
        class="col-md-8 col-12 mb-3"
        v-for="(theme, index) in themes"
        :key="index"
        @click="toggleAnswer(index)"
        data-aos="fade-in"
      >
        <div
          class="py-3 theme-wrapper px-3"
          :style="[
            clickedAnswer === index
              ? ' background-color: #e8fcd4; color: #201f1d;'
              : '',
          ]"
        >
          <div class="col-12 position-relative">
            <h4 class="my-2 mx-1 me-4">{{ theme.title }}</h4>
            <span
              class="expand-cross d-flex justify-content-center align-items-center"
              ><svg
                :style="[
                  clickedAnswer === index
                    ? 'transform:rotate(45deg)!important'
                    : '',
                ]"
                width="15"
                height="15"
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 5.04H5.04V9H3.96V5.04H0V3.96H3.96V0H5.04V3.96H9V5.04Z"
                  fill="currentColor"
                ></path></svg
            ></span>
          </div>

          <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
          >
            <div v-if="clickedAnswer === index" class="col-12 answer mt-3">
              <p>{{ theme.body }}</p>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      clickedAnswer: null,
      themes: [
        {
          title: "1. Develop music ecosystems",
          body: "Grant systems, funding, studios, rehearsal spaces, and multiple small to midsizevenues are integral components for a music ecosystem to blossom. Advocates & Advisors (A&A) board member and founding member of the Arab voice in Hip-Hop, Narcy, said during the Arab Wave 2.0 panel, ”If you have all of those and rotate through them, then you have a culture you start creating.”",
        },
        {
          title: "2. Creating authentic communities",
          body: "The first step to growing a meaningful community is to curate online and offline spaces for groups with shared values. Combining parties, events, radio, podcasts, and social media ensures a local foundation with a global reach. Ghazi Shami noted, “TikTok is amazing. Instagram is amazing. But there’s something to be said about touching people directly.”",
        },
        {
          title: "3. Leading through education",
          body: "XP Music Futures encouraged attendees to keep learning multiple facets of the music industry, such as legal contracts, touring, labels, distribution, NFTs, and CMS (Content Management System). Technological advancements are propelling Saudi Arabia to the forefront of the metaverse. Consequently, increased music and tech industry education will benefit the region and sustain its growth.",
        },
        {
          title: "4. Defining a unique identity",
          body: "Supporting artists to share perspectives on their terms, be it embracing their mixed heritage, regional identity, or singing in multiple languages—authenticity will encourage a sound that comes from a place of sincerity.",
        },
        {
          title: "5. Collaborative opportunities",
          body: "Encouraging cross-discipline collaboration between artists, agencies, brands, and music industry professionals helps to grow networks, build trust, learn from one another, expand possibilities and solve problems.",
        },
      ],
    };
  },
  methods: {
    toggleAnswer(e) {
      if (this.clickedAnswer === e) {
        this.clickedAnswer = null;
      } else {
        this.clickedAnswer = e;
      }
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>
<style scoped>
.expand-cross {
  position: absolute;
  right: 0.2rem;
  top: 0;
  bottom: 0;
  margin: auto;
  font-family: SkModernistBold;
  height: 1.8rem;
}
svg {
  transition: transform 650ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.theme-wrapper:hover svg {
  transform: rotate(180deg);
}
.theme-wrapper:hover {
  background-color: #e8fcd4;
  color: #201f1d;
}
.theme-wrapper {
  color: #e8fcd4;
  border: 1px solid #e8fcd4;
  border-radius: 40px;
  background-color: transparent;
  transition: all 0.15s ease-in-out;
}
.theme-wrapper:hover {
  cursor: pointer;
}
.expand-enter-active,
.expand-leave-active {
  transition: height 200ms ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

.title {
 line-height: 2rem;
  font-size: 1.8rem;
  z-index: 1;
  color:#e8fcd4;
}
.themes {
  min-height: auto;
  background: #201f1d;
  position: relative;
}
@media (min-width: 768px) {
  .themes {
    min-height: 100vh;
  }
  .title {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}

h3 {
  text-align: center;
  font-family: SkModernistBold;
  font-size: 2rem;
}
</style>