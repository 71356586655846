<template>
  <div id="summary" class="section explore">
    <!-- Circle shape background -->
    <div class="circle-bg"></div>
    <!-- Background image -->
    <div
      data-bgset=""
      class="bg-img lazyload row p-0 m-0 justify-content-center align-items-center"
    >
      <div
        data-aos="fade-in"
        class="col-md-6 col-12 explore-header text-center"
      >
        <div class="col-12">
          XP Outcomes Report<br />
          Edition one <br />
          Executive Summary 2022
        </div>
        <div class="col-12 mt-4">
          <div
            v-if="!showContent"
            @click="showContent = true"
            class="read-btn btn btn-outline-light"
          >
            Read
          </div>
          <MoreBtn v-else />
        </div>
      </div>

      <transition name="fade">
        <div
          v-if="showContent"
          class="col-md-6 col-12 row d-flex justify-content-center es-content pb-5"
        >
          <div class="col-12 pb-5 pb-md-0">
            KSA’s annual XP Music Futures goal is to expand opportunities for
            music industry professionals of all backgrounds, inspire future
            generations, and help lay the groundwork for establishing equitable
            industry policies for growth in the MENA region.
            <br />
            <br />
            The second edition of XP Music Futures was held in Riyadh on
            November 28 - 30, 2022. More than 2,500 people from 30 countries
            attended the three-day event, which included the XP Day and XP Nite
            programs. XP Day featured 228 speakers and 76 conference sessions,
            and XP Nite showcased 157 local and regional acts, with 273
            individual artists sharing the stage over three nights of
            performances.
            <br /><br />
            The XP Outcomes Report highlights vital takeaways from XP Day and XP
            Nite Programs. The Report delivers insights and critical facts into
            the MENA music industry and how XP is shaping one of the
            fastest-growing music regions in the world.
          </div>
          <!-- <div class="col-3 d-flex justify-content-center">
          <img class="img-fluid hero-img" src="@/assets/img/hero.jpg" alt="" />
        </div> -->
        </div>
      </transition>
      <div class="rounded-bottom"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      images: ["1", "2", "3", "4", "5", "6"],
      showContent: false,
    };
  },
};
</script>
<style scoped>
.es-content {
  z-index: 1;
  color:#E8FCD4;
}
.hero-img {
  border-radius: 2rem;
}

.read-btn {
  padding: 0.8rem 2rem 0.8rem 2rem;
  /* border: 1px solid white; */
  border-radius: 30px;
  z-index: 1;
}
.read-btn:hover {
  cursor: pointer;
}
.circle-bg {
  position: absolute;
  left: -30%;
  right: 0;
  top: 120vh;
  bottom: 0;
  /* background:#242321; */
  background: radial-gradient(#201f1d, #242321);
  /* background: pink; */
  height: 80vw;
  width: 80vw;
  border-radius: 50%;
  z-index: -1;
}
.explore-header {
 line-height: 2rem;
  font-size: 1.8rem;
  z-index: 1;
  color: #e8fcd4;
}
@media (min-width: 768px) {
  .explore-header {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}

.img-1 {
  transform: translateY(100%);
}
.img-2 {
  transform: translateY(50%);
}
.img-4 {
  transform: translateY(100%);
}
.img-5 {
  transform: translateY(50%);
}

.explore {
  min-height: calc(100vh + 5rem);
  background: #201f1d;
  position: relative;
  height: auto;
}

.bg-img {
  background: url("~@/assets/img/landing-ray.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: cover;
  width: 100%;
  height: calc(100vh + 5rem);
  position: relative;
}

@media (min-width: 768px) {
  .bg-img {
    background-attachment: fixed;
  }
}

.bg-img::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.577);
}
.rounded-bottom {
  border-radius: 50px;
  width: 100%;
  height: 5rem;
  bottom: 0;
  background: #201f1d;
  position: absolute;
}
h3 {
  text-align: center;
  font-family: SkModernistBold;
  font-size: 2rem;
}
.credit {
  font-size: 0.8rem;
  text-align: center;
  line-height: 1rem;
}
.name {
  font-family: SkModernistBold;
}
</style>