<template>
  <div
    id="numbers"
    class="section numbers container-fluid d-flex justify-content-center align-items-center flex-column p-0 pt-5 mt-5"
  >
    <div class="col-12 text-center number-title mb-5 pb-3 pt-5">
      XP in numbers
    </div>
    <!-- Icons -->
    <div class="container-fluid icons-container">
      <div
     data-aos="fade"
      :data-aos-delay="index * 10"
        v-for="(icon, index) in numbers"
        :key="index"
        class="icon-wrapper d-flex flex-column justify-content-center align-items-center"
        :id="`icon-${index}`"
      >
        <div   class="icon-circle position-relative">
          <div class="icon-number">{{ icon.val }}</div>
          <img
            class="icon"
            :src="require(`@/assets/img/icons/${index}.svg`)"
            alt=""
          />
          <div class="icon-name">
            <small>{{ icon.name }}</small>
          </div>
        </div>
      </div>

      <!-- <div
        v-for="index in 5"
        :key="index"
        class="small-circle"
        :style="smallBubble()"
      ></div> -->

      <div   data-aos="fade" class="small-circle" id="sc-1"></div>

      <div   data-aos="fade" class="small-circle" id="sc-2"></div>
      <div   data-aos="fade" class="small-circle" id="sc-3"></div>
      <div   data-aos="fade" class="small-circle" id="sc-4"></div>
      <div   data-aos="fade" class="small-circle" id="sc-5"></div>
    </div>

    <div class="col-12 d-flex justify-content-center py-5 mt-5">
      <MoreBtn />
    </div>
    <div class="col-12 p-0 m-0">
      <div
        class="bg-img m-0 d-flex justify-content-center align-items-center p-0"
      >
        <div class="caption">
          Maestro Xperience: Fireside Chat with DJ Khaled and Larry Jackson
        </div>

        <div class="rounded-bottom"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      numbers: [
        { val: "6.8 million", name: "Estimated views" },
        { val: 315, name: "Pieces of media coverage" },
        { val: "5,400", name: "Social Media engagements" },
        { val: "730,950", name: "Total media reach" },
        { val: 2559, name: "Attendees" },
        { val: 228, name: "Speakers at XP Day" },
        { val: 167, name: "Companies represented" },
        { val: 157, name: "Acts at XP Nite" },
        { val: 116, name: "Performances" },
        { val: 261, name: "Individual artists" },
        { val: 33, name: "Brands represented" },
        { val: 7, name: "Dancefloors" },
        { val: 30, name: "Countries" },
      ],
    };
  },
};
</script>
<style scoped>
#sc-1 {
  transform: translate(-10rem, -20rem);
  height: 1.5rem;
  width: 1.5rem;
}
#sc-2 {
  transform: translate(3rem, -18rem);
  height: 2.5rem;
  width: 2.5rem;
}
#sc-3 {
  transform: translate(-13rem, 12rem);
  height: 1rem;
  width: 1rem;
}
#sc-4 {
  transform: translate(-2rem, 8rem);
  height: 2rem;
  width: 2rem;
}
#sc-5 {
  transform: translate(11rem, 23rem);
  height: 2rem;
  width: 2rem;
}

.icon-circle {
  width: 100%;
  height: 100%;
}
.icons-container {
  position: relative;
  min-height: 100vh;
  height: 54rem;
  color: #e8fcd4;
  overflow: hidden;
}

.number-title {
  line-height: 2rem;
  font-size: 1.8rem;
  color: #e8fcd4;
}
.rounded-bottom {
  border-radius: 50px;
  transform: rotate(180deg);
  width: 100%;
  height: 5rem;
  top: 0;
  background: #201f1d;
  position: absolute;
}

.sub-title {
  font-size: 1.5rem;
}

.report-wrapper {
  margin-top: 2rem;
  min-height: 100vh;
  width: 100%;
}
.card-left,
.card-right {
  border-radius: 50px;
}
.card-left {
  min-height: 20rem;
}

.numbers {
  height: auto;
  min-height: 100vh;
}
.icon-wrapper {
  border: 1px solid #e8fcd4b8;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  margin: auto;
  background: #2d2c2a6b;
  border-radius: 50%;
}
#icon-0 {
  width: 16rem;
  height: 16rem;
  transform: scale(0.8);
}

#icon-0 img {
  width: 100%;
  height: 100%;
}

#icon-1 {
  width: 12rem;
  height: 12rem;
  transform: translate(7rem, 11.5rem) scale(0.8);
}
#icon-1 img {
  width: 70%;
  height: 70%;
}
#icon-2 {
  width: 13rem;
  height: 13rem;
  transform: translate(-7.5rem, -12.5rem) scale(0.8);
}
#icon-3 {
  width: 11rem;
  height: 11rem;
  transform: translate(-8.5rem, 7.5rem) scale(0.8);
}

#icon-4 {
  width: 13rem;
  height: 13rem;
  transform: translate(8rem, -7.5rem) scale(0.8);
}
#icon-5 {
  width: 10rem;
  height: 10rem;
  transform: translate(-2rem, 14rem) scale(0.8);
}
#icon-6 {
  width: 7.5rem;
  height: 7.5rem;
  transform: translate(-9.5rem, -3.5rem) scale(0.8);
}
#icon-6 img {
  width: 40%;
  height: 40%;
}
#icon-6 .icon-number {
  padding-top: 0.5rem;
}
#icon-6 .icon-name {
  padding-bottom: 0rem;
  bottom: 0.6rem;
}
#icon-7 {
  width: 7rem;
  height: 7rem;
  transform: translate(5rem, 19.5rem) scale(0.8);
}
#icon-7 img {
  width: 40%;
  height: 40%;
}
#icon-7 .icon-number {
  padding-top: 0.5rem;
}
#icon-7 .icon-name {
  padding-bottom: 0rem;
  bottom: 0.6rem;
}
#icon-7 img {
  width: 50%;
  height: 50%;
}
#icon-7 .icon-number {
  padding-top: 0.5rem;
}
#icon-7 .icon-name {
  padding-bottom: 0rem;
  bottom: 0.6rem;
}
#icon-8 {
  width: 8rem;
  height: 8rem;
  transform: translate(8rem, -18rem) scale(0.8);
}
#icon-8 .icon-number {
  padding-top: 0.2rem;
}
#icon-8 .icon-name {
  padding-bottom: 0rem;
  bottom: 1rem;
  max-width: 90%;
}
#icon-8 img {
  width: 50%;
  height: 50%;
}

#icon-9 {
  width: 9rem;
  height: 9rem;
  transform: translate(1.5rem, -13rem) scale(0.8);
}
#icon-10 {
  width: 9rem;
  height: 9rem;
  transform: translate(9rem, 3rem) scale(0.8);
}
#icon-11 {
  width: 8rem;
  height: 8rem;
  transform: translate(-8rem, 17rem) scale(0.8);
}
#icon-11 .icon-number {
  padding-top: 0.2rem;
}
#icon-11 .icon-name {
  padding-bottom: 0rem;
  bottom: 0.8rem;
}
#icon-11 img {
  width: 50%;
  height: 50%;
}
#icon-12 {
  width: 9rem;
  height: 9rem;
  transform: translate(-3rem, -22rem) scale(0.8);
}
#icon-11 img {
  width: 50%;
  height: 50%;
}

.small-circle {
  background: #2d2c2a6b;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
@media (min-width: 768px) {
  #sc-1 {
  transform: translate(-21rem, -16rem);
  height: 2rem;
  width: 2rem;
}
#sc-2 {
  transform: translate(29rem, -20rem);
  height: 3rem;
  width: 3rem;
}
#sc-3 {
  transform: translate(-33rem, 12rem);
  height: 2rem;
  width: 2rem;
}
#sc-4 {
  transform: translate(31rem, 7rem);
  height: 4rem;
  width: 4rem;
}
#sc-5 {
  transform: translate(22rem, 25rem);
  height: 2rem;
  width: 2rem;
}
  .number-title {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  .card-left {
    min-height: 35rem;
  }

  .report-wrapper {
    margin-top: 10rem;
    min-height: 100vh;
    width: 100%;
  }
  #icon-0 {
    width: 16rem;
    height: 16rem;
    transform: scale(1);
  }

  #icon-0 img {
    width: 100%;
    height: 100%;
  }

  #icon-1 {
    width: 12rem;
    height: 12rem;
    transform: translate(10.5rem, 11rem);
  }
  #icon-1 img {
    width: 70%;
    height: 70%;
  }
  #icon-2 {
    width: 13rem;
    height: 13rem;
    transform: translate(-10.5rem, -12.5rem);
  }
  #icon-3 {
    width: 11rem;
    height: 11rem;
    transform: translate(-17rem, 9rem);
  }

  #icon-4 {
    width: 13rem;
    height: 13rem;
    transform: translate(12rem, -9rem);
  }
  #icon-5 {
    width: 10rem;
    height: 10rem;
    transform: translate(0.7rem, 18.2rem);
  }
  #icon-6 {
    width: 7.5rem;
    height: 7.5rem;
    transform: translate(-12.5rem, -0.5rem);
  }
  #icon-6 img {
    width: 40%;
    height: 40%;
  }
  #icon-6 .icon-number {
    padding-top: 0.5rem;
  }
  #icon-6 .icon-name {
    padding-bottom: 0rem;
    bottom: 0.6rem;
  }
  #icon-7 {
    width: 7rem;
    height: 7rem;
    transform: translate(23rem, 9rem);
  }
  #icon-7 img {
    width: 40%;
    height: 40%;
  }
  #icon-7 .icon-number {
    padding-top: 0.5rem;
  }
  #icon-7 .icon-name {
    padding-bottom: 0rem;
    bottom: 0.6rem;
  }
  #icon-7 img {
    width: 50%;
    height: 50%;
  }
  #icon-7 .icon-number {
    padding-top: 0.5rem;
  }
  #icon-7 .icon-name {
    padding-bottom: 0rem;
    bottom: 0.6rem;
  }
  #icon-8 {
    width: 8rem;
    height: 8rem;
    transform: translate(21.5rem, -12rem);
  }
  #icon-8 .icon-number {
    padding-top: 0.2rem;
  }
  #icon-8 .icon-name {
    padding-bottom: 0rem;
    bottom: 1rem;
    max-width: 90%;
  }
  #icon-8 img {
    width: 50%;
    height: 50%;
  }

  #icon-9 {
    width: 9rem;
    height: 9rem;
    transform: translate(4rem, -15rem);
  }
  #icon-10 {
    width: 9rem;
    height: 9rem;
    transform: translate(20rem, 0);
  }
  #icon-11 {
    width: 8rem;
    height: 8rem;
    transform: translate(-5rem, 12rem);
  }
  #icon-11 .icon-number {
    padding-top: 0.2rem;
  }
  #icon-11 .icon-name {
    padding-bottom: 0rem;
    bottom: 0.8rem;
  }
  #icon-11 img {
    width: 50%;
    height: 50%;
  }
  #icon-12 {
    width: 9rem;
    height: 9rem;
    transform: translate(-20rem, -8rem);
  }
  #icon-11 img {
    width: 50%;
    height: 50%;
  }
}

.bg-img {
  background: url("~@/assets/img/larry-khalid.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: cover;
  background-position: 78% center;
  width: 100%;
  height: calc(100vh + 5rem);
  position: relative;
  padding: 0;
}
.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2rem;
  color: #e8fcd4;
}
@media (min-width: 768px) {
  .bg-img {
    background-position: center;
  }
}

.bg-img::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  /* background-color: rgba(0, 0, 0, 0.577); */
}

.icon-number {
  text-align: center;
  font-size: 1.8rem;
  line-height: 2rem;
  padding-top: 1rem;
  font-family: SkModernistRegular;
  left: 0;
  right: 0;
  margin: auto;
  top: 0.5rem;
  position: absolute;
}
.icon-name {
  font-family: SkModernistRegular;
  text-align: center;
  font-size: 1rem;
  padding-bottom: 0.2rem;
  line-height: 1rem;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 1rem;
  position: absolute;
  max-width: 60%;
}

img.icon {
  width: 60%;
  height: 60%;
  filter: invert(94%) sepia(7%) saturate(802%) hue-rotate(44deg)
    brightness(104%) contrast(98%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.title {
  font-size: 3.2rem;
  line-height: 3.5rem;
}
.number {
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
}

.name {
  line-height: 2rem;
  font-family: SkModernistBold;
}
</style>