import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/styles/bootstrap.min.css'
import { createPrismic } from "@prismicio/vue";
import AOS from 'aos'
import 'aos/dist/aos.css'
import MoreBtn from '@/components/ui/MoreBtn.vue'
import DlBtn from '@/components/ui/DlBtn.vue'

const prismic = createPrismic({
  endpoint: "vibelab",
});

AOS.init({
  offset: 100, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 800, // values from 0 to 3000, with step 50ms
  easing: 'ease' // default easing for AOS animations
  // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
})

const app = createApp(App)
app.use(prismic)
app.component('DlBtn', DlBtn)
app.component('MoreBtn', MoreBtn)
app.use(router)
app.mount('#app')
